@font-face {
    font-family: 'Raleway-normal';
    src: url('Raleway.woff2') format('woff2'),
        url('Raleway.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway-bold';
    src: url('Raleway-Bold.woff2') format('woff2'),
        url('Raleway-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway-medium';
    src: url('Raleway-Medium.woff2') format('woff2'),
        url('Raleway-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Mon Garamond';
    src: url('MonGaramondBold.woff2') format('woff2'),
        url('MonGaramondBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
